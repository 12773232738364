import imageOne from '../images/description/02.jpg';
import imageTwo from '../images/description/03.jpg';
import imageThree from '../images/description/01.jpg';

const images = {
    imageOne,
    imageTwo,
    imageThree
}

export default images;